import { createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app";

export type MessageState = {
    status?: "success" | "error" | "warning" | "info";
    message?: string;
    timeOut?: number;
};

const initialState: MessageState = {};

const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<MessageState>) => {
            state.message = action.payload.message;
            state.status = action.payload.status;
            state.timeOut = action.payload.timeOut;
        },
        clearMessage: (state) => {
            state.message = initialState.message;
            state.status = initialState.status;
            state.timeOut = initialState.timeOut;
        },
    },
});

const selectSelf = (state: RootState) => state.message;

export const selectMessage = createDraftSafeSelector(
    selectSelf,
    (state) => state
);

export const { setMessage, clearMessage } = messageSlice.actions;
export default messageSlice.reducer;
