import api from "api/utils";

const baseUrl = "api/i18n";

const getTranslations = (languageCode: string) => {
    return api.query<{languageCode: string}, Record<string, string>>({
        baseUrl: baseUrl,
        queryParams: {languageCode}
    });
};

const getLanguages = () => {
    return api.query<undefined, Record<string, string>>({
        baseUrl: `${baseUrl}/languages`
    });
};

const i18nService = { getTranslations, getLanguages };
export default i18nService;
