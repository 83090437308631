export enum ResultStatus {
    New = 0,
    Busy = 1,
    Error = 2
}

export enum Role {
    Administrator = "Administrator",
    UserManagement = "UserManagement",
    ExternalWeighing = "ExternalWeighing",
    QueueMonitor = "QueueMonitor",
    NgsConfiguration = "NgsConfiguration",
}
