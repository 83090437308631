import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SetUserProfile, UserProfile } from "api/models/user";
import { userService } from "api/services";
import { RootState } from "app";
import { setMessage } from "features/message";

export type UserState = {
  profile?: UserProfile | null;
  language?: string;
};

const initialState: UserState = {};

export const getUserProfile = createAsyncThunk(
  "get/userProfile",
  async (arg, thunkAPI) => {
    return await userService.getProfile();
  }
);

export const setUserProfile = createAsyncThunk(
  "set/userProfile",
  async (arg: SetUserProfile, { dispatch, rejectWithValue }) => {
    try {
      await userService.setProfile(arg);

      dispatch(
        setMessage({
          message: "ProfileUpdateSuccess",
          status: "success",
          timeOut: 5000,
        })
      );

      return arg;
    } catch (error) {
      dispatch(
        setMessage({
          message: "ProfileUpdateError",
          status: "error",
        })
      );

      return rejectWithValue(null);
    }
  }
);

const userSlice = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state) => {
      state.profile = undefined;
    });
    builder.addCase(
      getUserProfile.fulfilled,
      (state, action: PayloadAction<UserProfile | null>) => {
        state.profile = action.payload;
      }
    );
    builder.addCase(getUserProfile.rejected, (state) => {
      state.profile = null;
    });
    builder.addCase(
      setUserProfile.fulfilled,
      (state, action: PayloadAction<SetUserProfile | null>) => {
        if (!action.payload) return;

        state.profile = {
          ...state.profile!,
          ...action.payload,
        };
      }
    );
  },
});

const selectSelf = (state: RootState) => state.user;
export const selectLanguage = createDraftSafeSelector(
  selectSelf,
  (state) => state.language
);
export const selectUser = createDraftSafeSelector(
  selectSelf,
  (state) => state.profile
);
export const selectRoles = createDraftSafeSelector(
  selectSelf,
  (state) => state.profile?.roles
);
export const selectMyProfileForm = createDraftSafeSelector(
  selectSelf,
  (state) => ({
    name: state.profile!.name,
    email: state.profile!.email,
  })
);

export const { setLanguage } = userSlice.actions;
export default userSlice.reducer;
