import { SetUserRole, User } from "api/models/configuration";
import api from "api/utils";

const baseUrl = "api/configuration";

const getUsers = () => {
    return api.query<undefined, User[]>({
        baseUrl: `${baseUrl}/users`
    });
};

const setUserRole = async ({ userId, role, add }: SetUserRole) => {
    return api.command({
        baseUrl: `${baseUrl}/userrole/${userId}`,
        body: { role, add },
        method: "PUT"
    });
};

const deleteUser = async (userId: number) => {
    return api.command({
        baseUrl: `${baseUrl}/user/${userId}`,
        method: "DELETE"
    });
};

const configurationService = {
    getUsers,
    setUserRole,
    deleteUser,
};

export default configurationService;
