import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetUserRole, User } from "api/models/configuration";
import { deleteUser, getUsers, setUserRole } from "./actions";

export type ConfigurationState = {
    users?: User[];
};

const initialState: ConfigurationState = {};

const configurationSlice = createSlice({
    name: "configuration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            getUsers.fulfilled,
            (state, action: PayloadAction<User[] | null>) => {
                if (action.payload) state.users = action.payload;
            }
        );
        builder.addCase(
            setUserRole.fulfilled,
            (state, action: PayloadAction<SetUserRole>) => {
                const user = state.users?.find((u) => u.id === action.payload.userId);

                if (user) {
                    if (action.payload.add) {
                        console.log('add', action.payload); //? Len: somehow, having this comment allows role removal on components/UserTableRow.tsx ... which is what happens in the next code block, not this one.
                        if (user.roles.indexOf(action.payload.role) === -1)
                            user.roles.push(action.payload.role);
                    } else {
                        var index = user.roles.indexOf(action.payload.role);
                        while (index !== -1) {
                            user.roles.splice(index, 1);
                            index = user.roles.indexOf(action.payload.role);
                        }
                    }
                }
            }
        );
        builder.addCase(
            deleteUser.fulfilled,
            (state, action: PayloadAction<number>) => {
                const index = state.users?.findIndex((u) => u.id === action.payload);
                if (index !== undefined && index > -1) state.users?.splice(index, 1);
            }
        );
    },
});

export default configurationSlice.reducer;
