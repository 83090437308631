import { Role } from "api/models/infrastructure";
import { useAppSelector } from "app";
import { selectUser } from "features/user";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

type Props = {
  requiredRoles?: Role[];
};

const RequireAuth = ({
    requiredRoles,
    children,
}: React.PropsWithChildren<Props>) => {
    const user = useAppSelector(selectUser);
    const location = useLocation();

    if (!user) return <></>;

    if (requiredRoles && !(requiredRoles?.some(rr => user.roles.indexOf(rr) >= 0) || user.roles.indexOf(Role.Administrator) >= 0)) {
        return (
            <Navigate
                to="/unauthorized"
                replace
                state={{ path: location.pathname }}
            />
        );
    }

    return <>{children}</>;
};

export default RequireAuth;
