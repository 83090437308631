import { CompanySearchCriteria, CompanySearchReply } from "api/models/companies";
import api from "api/utils";

const baseUrl = "api/companies";

const searchCompanies =  async (criteria?: CompanySearchCriteria) => {
    return await  api.query<CompanySearchCriteria, CompanySearchReply>({
        baseUrl: baseUrl,
        queryParams: criteria,
        debounce: {
            delayMs: 500,
            timeoutKey: "searchCompanies"
        }
    });
};

const companyService = {
    searchCompanies
};

export default companyService;