export const cookieKeys = {
    lang: "ams-lang",
    version: "ams_version",
};

const get = (key: string): string | null => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
};

const set = (key: string, value: string, expirationInDays: number = 7) => {
    const d = new Date();
    d.setTime(d.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
};

const tryDelete = (key: string): void => {
    if (get(key)) set(key, "", -1);
};

const cookieService = { get, set, tryDelete };
export default cookieService;
