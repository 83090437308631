import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
} from "@mui/material";
import React from "react";
import { Image } from "shared/components";

const SearchField = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        component={IconButton}
                        position="end"
                        sx={{
                            p: 0,
                            "& > img": {
                                filter: (theme: any) =>
                                    theme.palette.secondary.filter?.main,
                                ":hover": {
                                    cursor: "text",
                                },
                            },
                        }}
                    >
                        <Image fileName="search.svg" alt="Search" />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchField;
