import { UserProfile, SetUserProfile } from "api/models/user";
import api from "api/utils";

const baseUrl = "api/user";

const getProfile = async () => {
    return api.query<undefined, UserProfile>({
        baseUrl: baseUrl
    });
};

const setProfile = async (userProfile: SetUserProfile) => {
    return api.command({
        baseUrl: baseUrl,
        body: userProfile,
        method: "POST"
    });
};

const userService = { getProfile, setProfile };
export default userService;
