import { authService } from "api/services";

type ApiCommand<T> = {
    baseUrl: string;
    body?: T;
    method: "POST" | "PUT" | "DELETE"
    debounce?: Debounce;
}

type ApiQuery<T> = {
    baseUrl: string;
    queryParams?: T;
    debounce?: Debounce;
}

type Debounce = {
    delayMs: number;
    timeoutKey: string;
};

async function query<T1, T2>({ baseUrl, queryParams, debounce }: ApiQuery<T1>): Promise<T2 | null> {
    const url = getUrl(baseUrl, queryParams);
    const response = debounce
        ? await debouncer(fetch, debounce)(url)
        : await fetch(url);
    return handleResponse<T2>(response);
}

async function command<T1, T2>({ baseUrl, body, method, debounce }: ApiCommand<T1>) {
    const request ={
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    const response = debounce
        ? await debouncer(fetch, debounce)(baseUrl, request)
        : await fetch(baseUrl, request);
    return handleResponse<T2>(response);
}

function getUrl(baseUrl: string, queryParams?: any) {
    return queryParams
        ? `${baseUrl}?${queryString(queryParams)}`
        : baseUrl;
}

function handleResponse<T>(response: Response) {
    if (response.status === 401) authService.login(window.location.href);
    if (response.status === 403) window.location.href = "/unauthorized";
    if (!response.ok) throw new Error(response.statusText);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") !== -1)
        return response.json() as Promise<T>;

    return null;
}

// Will omit null/undefined values
const queryString = (query: { [key: string]: any } = {}): string => {
    const params = new URLSearchParams();

    Object.entries(query)
        .filter(([_, value]) => value !== undefined)
        .forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
                Object.entries(value)
                    .filter(([_, vValue]) => vValue !== undefined)
                    .forEach(([vKey, vValue]: any) =>
                        params.append(`${key}[${vKey}]`, vValue)
                    );
            } else {
                params.append(key, value?.toString());
            }
        });

    return params.toString();
};

let debounceTimer: any = {};
const debouncer = (func: Function, {delayMs: delay, timeoutKey}: Debounce): any => {
    return (...args: any[]) => {
        return new Promise((resolve, reject) => {
            clearTimeout(debounceTimer[timeoutKey]);
            debounceTimer[timeoutKey] = setTimeout(() => {
                try {
                    resolve(func(...args));
                } catch (err) {
                    reject(err);
                }
            }, delay);
        });
    };
}

const api = { query, command };
export default api;
