const hex2rgba = (hex: string, alpha = 1) => {
    const rgb = hex
        .match(hex.length <= 4 ? /\w/g : /\w\w/g)
        ?.map((x) => parseInt(x.length < 2 ? `${x}${x}` : x, 16));

    return rgb ? `rgba(${rgb.toString()},${alpha})` : undefined;
};

const colorService = { hex2rgba };

export default colorService;
