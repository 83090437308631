import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import messageReducer from "features/message/slice";
import i18nReducer from "features/locale/slice";
import userReducer from "features/user/slice";
import configurationReducer from "features/configuration/slice";
import externalWeighingReducer from "features/externalWeighing/slice";
import queuesReducer from "features/queueMonitor/slice";
import ngsConfigurationReducer from "features/NgsConfiguration/slice";
import companyReducer from "features/company/slice";

export const store = configureStore({
    reducer: {
        message: messageReducer,
        i18n: i18nReducer,
        user: userReducer,
        configuration: configurationReducer,
        externalWeighing: externalWeighingReducer,
        queues: queuesReducer,
        ngsConfiguration: ngsConfigurationReducer,
        company: companyReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
