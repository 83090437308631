import { createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CccRegistrationConfig, NgsConfigurationModel } from "api/models/ngsConfiguration";
import { ngsConfigurationService } from "api/services";
import { RootState } from "app";
import {setMessage} from "features/message";

export type NgsConfigurationState = {
  cccRegistrationConfig: CccRegistrationConfig;
  loading: boolean;
};

const initialState: NgsConfigurationState = {
  cccRegistrationConfig: {
    clonesEnabled: false,
    defaultMaxInstallations: 0,
    companyMaxInstallations: []
  },
  loading: true,
};

export const getNgsConfiguration = createAsyncThunk(
  "ngsConfiguration/get",
  async (_) => await ngsConfigurationService.getNgsConfiguration()
);

export const setCccRegistrationConfig = createAsyncThunk(
  "cccRegistration/set",
  async (cccRegistrationConfig: CccRegistrationConfig, { dispatch }) => {
    try {
      if (!cccRegistrationConfig) return;
      // Optimistic update
      dispatch(setCccRegistrationState(cccRegistrationConfig));
      return await ngsConfigurationService.setNgsConfiguration({ cccRegistrationConfig });
    } catch (e) {
      console.error(e);
      dispatch(setMessage({
        message: "CccRegistrationError",
        status: "error"
      }));
      dispatch(getNgsConfiguration());
    }
  }
);

const ngsConfigurationSlice = createSlice({
  name: "ngsConfiguration",
  initialState,
  reducers: {
    setCccRegistrationState: (state, action: PayloadAction<CccRegistrationConfig>) => {
      state.cccRegistrationConfig = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getNgsConfiguration.fulfilled,
        (state, action: PayloadAction<NgsConfigurationModel | null>) => {
          if (action.payload) {
              state.cccRegistrationConfig = {
                  ...action.payload.cccRegistrationConfig,
                  companyMaxInstallations: action.payload.cccRegistrationConfig.companyMaxInstallations ?? []
              }
          }
          state.loading = false;
        }
      );
    builder.addCase(
      getNgsConfiguration.pending,
      (state) => {
        state.loading = true;
      }
    );
  },
});

const selectSelf = (state: RootState) => state.ngsConfiguration;
export const selectCccRegistrations = createDraftSafeSelector(
  selectSelf,
  (state) => state.cccRegistrationConfig
);

export const selectMaxInstallationById = (id?: number) => {
  return createDraftSafeSelector(
    selectSelf,
    (state) => state.cccRegistrationConfig?.companyMaxInstallations.find(x => x.id === id)
  );
}

export const selectLoadingNgsConfigurationPage = createDraftSafeSelector(
  selectSelf,
  (state) => state.loading
);

const { setCccRegistrationState } = ngsConfigurationSlice.actions;
export default ngsConfigurationSlice.reducer;
