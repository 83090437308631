import api from "../utils";
import {NgsConfigurationModel} from "../models/ngsConfiguration";

const baseUrl = "api/ngsconfiguration";

const getNgsConfiguration = () => {
    return api.query<undefined, NgsConfigurationModel>({
        baseUrl: baseUrl
    });
};

const setNgsConfiguration = (ngsConfiguration: NgsConfigurationModel) => {
    return api.command<NgsConfigurationModel, NgsConfigurationModel>({
        baseUrl: baseUrl,
        body: ngsConfiguration,
        method: "POST",
        debounce: {
            delayMs: 500,
            timeoutKey: "setNgsConfiguration"
        }
    });
};

const ngsConfigurationService = {
    getNgsConfiguration,
    setNgsConfiguration,
};

export default ngsConfigurationService;