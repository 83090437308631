import { useAppDispatch, useAppSelector } from "app";
import { getLanguages, getTranslations, selectLanguages, selectT9N, } from "features/locale";
import { selectLanguage, selectUser, setLanguage } from "features/user";
import { useEffect } from "react";
import { cookieKeys, cookieService } from "services";

const fallbackLanguage = "en";

const Localization = ({ children }: React.PropsWithChildren<{}>) => {
    const user = useAppSelector(selectUser);
    const t9n = useAppSelector(selectT9N);
    const currentlanguage = useAppSelector(selectLanguage);
    const languages = useAppSelector(selectLanguages);
    const cookieLanguage = cookieService.get(cookieKeys.lang);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getLanguages());
    }, [dispatch]);

    useEffect(() => {
        if (!currentlanguage) {
            dispatch(
                setLanguage(cookieLanguage ?? user?.language ?? fallbackLanguage)
            );
        }

        if (currentlanguage && (currentlanguage !== cookieLanguage || !t9n)) {
            cookieService.set(cookieKeys.lang, currentlanguage);

            if (languages[currentlanguage])
                dispatch(getTranslations(currentlanguage));
        }
    }, [dispatch, cookieLanguage, currentlanguage, t9n, languages, user]);

    if (!t9n) return <></>;

    return <>{children}</>;
};

export default Localization;
