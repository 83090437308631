import { BaseResponse } from "api/models/infrastructure";
import { ResetQueueRequest } from "api/models/queues";
import api from "api/utils";


const baseUrl = "api/queues";

const resetQueue = async (request: ResetQueueRequest) => {
    return api.command<ResetQueueRequest, BaseResponse>({
        baseUrl: baseUrl,
        body: request,
        method: "POST"
    });
};

const queuesService = {
    resetQueue
};

export default queuesService;