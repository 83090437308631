import React, { useCallback, useState } from "react";
import { Order, sortService } from "services";

function useSort<T>(
    initialValue: keyof T,
    initialOrder: Order = "asc"
): [keyof T | undefined, Order, any, (order: Order, orderBy?: keyof T) => any] {
    const [order, setOrder] = useState<Order>(initialOrder);
    const [orderBy, setOrderBy] = useState<keyof T>(initialValue);

    const handleSort = useCallback(
        (event: React.MouseEvent<unknown>, property: keyof T) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );

    const comparer = (order: Order, orderBy?: keyof T) =>
        sortService.getComparer(order, orderBy ?? initialValue);

    return [orderBy, order, handleSort, comparer];
}

export default useSort;
