import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableCellProps,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { Order } from "services";

type Props<T> = {
    headCells: (TableCellProps & {
        id?: keyof T;
        name?: string;
        disableSort?: boolean;
        hide?: boolean;
        childComponent?: React.ReactNode;
    })[][];
    onRequestSort?: (
        event: React.MouseEvent<unknown>,
        property: keyof T
    ) => void;
    order?: Order;
    orderBy?: string;
};

const TableHeader = <T extends unknown>(props: Props<T>) => {
    const { headCells, order, orderBy, onRequestSort } = props;

    const createSortHandler =
        (property: keyof T) => (event: React.MouseEvent<unknown>) => {
            onRequestSort && onRequestSort(event, property);
        };

    return (
        <TableHead>
            {headCells.map((hc, i) => (
                <TableRow key={i}>
                    {hc
                        .filter(({ hide }) => !hide)
                        .map(
                            (
                                {
                                    id,
                                    name,
                                    disableSort,
                                    hide,
                                    childComponent,
                                    ...rest
                                },
                                index
                            ) =>
                                id ? (
                                    <TableCell
                                        key={id.toString()}
                                        sx={{ pl: 2 }}
                                        sortDirection={
                                            orderBy === id ? order : false
                                        }
                                        {...rest}
                                    >
                                        {onRequestSort && !disableSort ? (
                                            <TableSortLabel
                                                active={orderBy === id}
                                                direction={
                                                    orderBy === id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={createSortHandler(id)}
                                            >
                                                {name}
                                                {orderBy === id ? (
                                                    <Box
                                                        component="span"
                                                        sx={visuallyHidden}
                                                    >
                                                        {order === "desc"
                                                            ? "sorted descending"
                                                            : "sorted ascending"}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            <>{childComponent || name}</>
                                        )}
                                    </TableCell>
                                ) : (
                                    <TableCell key={index} />
                                )
                        )}
                </TableRow>
            ))}
        </TableHead>
    );
};

export default TableHeader;
