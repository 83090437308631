import {
    Brand,
    ExternalWeighing,
    SetExternalWeighing,
} from "api/models/externalWeighing";
import api from "api/utils";

const baseUrl = "api/externalweighing";

const getExternalWeighings = () => {
    return api.query<undefined, ExternalWeighing[]>({
        baseUrl: baseUrl
    });
};

const setExternalWeighing = (externalWeighing: SetExternalWeighing) => {
    return api.command<SetExternalWeighing, ExternalWeighing>({
        baseUrl: baseUrl,
        body: externalWeighing,
        method: "POST"
    });
};

const deleteExternalWeighing = (id: number) => {
    return api.command<undefined, any>({
        baseUrl: `${baseUrl}/${id}`,
        method: "DELETE"
    });
};

const getBrands = () => {
    return api.query<undefined, Brand[]>({
        baseUrl: `${baseUrl}/brands`
    });
};

const externalWeighingService = {
    getExternalWeighings,
    setExternalWeighing,
    deleteExternalWeighing,
    getBrands
};

export default externalWeighingService;
