import { Button, Typography } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React from "react";

const Unauthorized = () => {
    const t9n = useAppSelector(selectT9N);

    return (
        <>
            <Typography variant="h1">{t9n?.Unauthorized}</Typography>

            <form id="logoutAxalta-form" action="auth/logout" method="post">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => (document.getElementById("logoutAxalta-form") as HTMLFormElement).submit()}>
                    {t9n?.Logout}
                </Button>
            </form>
        </>
    );
};

export default Unauthorized;
