import { actions, getQueueMonitorSettings } from "./actions";
import {
    PayloadAction,
    createSlice,
    isPending,
    isRejected
} from "@reduxjs/toolkit";
import { QueueCount, QueueCountsResponse, ResetQueueRequest } from "api/models/queues";
import { QueueMonitorSettings } from "api/models/settings";

export type QueuesState = {
    disableReprocess: string[],
    latestCheckDate: string,
    loading: {
        brandFamilies: boolean;
    },
    queues: Record<string, QueueCount>,
    queuesSuccess: boolean | undefined,
    settings: QueueMonitorSettings;
};

export const initialState: QueuesState = {
    disableReprocess: [],
    latestCheckDate: "",
    loading: {
        brandFamilies: false,
    },
    queues: {},
    queuesSuccess: undefined,
    settings: {
        brandFamilies: [],
        badAlarmLevel: 20,
        badWarningLevel: 10,
        resultAlarmLevel: 20,
        resultWarningLevel: 10
    }
};

const queuesSlice = createSlice({
    name: "queues",
    initialState,
    reducers: {
        getQueues(state, action: PayloadAction<QueueCountsResponse>) {
            state.disableReprocess = [];
            if (!action.payload?.success) {
                state.queuesSuccess = false;
            }
            else {
                state.latestCheckDate = new Date(action.payload.timestamp).toLocaleString();
                state.queues = action.payload.items;
                state.queuesSuccess = true;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getQueueMonitorSettings.fulfilled,
            (state, { payload }) => {
                state.settings = payload;
                state.loading.brandFamilies = false;
            });
        builder.addMatcher(isPending,
            (state, { type, meta: { arg } }) => {
                switch (type) {
                    case `${actions.getQueueMonitorSettings}/pending`:
                        state.loading.brandFamilies = true;
                        break;
                    case `${actions.resetQueue}/pending`:
                        const brandFamilyCode = (arg as ResetQueueRequest).brandFamilyCode;
                        state.disableReprocess = [...state.disableReprocess, brandFamilyCode];
                        break;
                }
            });
        builder.addMatcher(isRejected,
            (state, { type }) => {
                switch (type) {
                    case `${actions.getQueueMonitorSettings}/rejected`:
                        state.loading.brandFamilies = false;
                        break;
                }
            });
    }
});

export const { getQueues } = queuesSlice.actions;
export default queuesSlice.reducer;