import { useState } from "react";

const rowsPerPage = 25;

function usePaging(initialPage: number = 0): [number, number, any] {
    const [page, setPage] = useState(initialPage);
    return [page, rowsPerPage, setPage];
}

export default usePaging;
