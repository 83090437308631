declare global {
    interface String {
        format(...replacements: any[]): String;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
    var s = this;
    for (var i = 0; i < arguments.length; i++) {
        var reg = new RegExp("\\{" + i + "\\}", "gm");
        if (arguments[i] !== undefined || arguments[i] !== null)
            s = s.replace(reg, arguments[i]);
    }
    return s;
};

export { };
