import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { UserProfile } from "api/models/user";
import { useAppSelector } from "app";
import { joinPaths, RoutePathDefinition, routes } from "app/routing";
import { selectT9N } from "features/locale";
import { selectUser } from "features/user";
import React from "react";
import { generatePath, NavLink, useLocation } from "react-router-dom";
import { Image } from "shared/components";
import { useMenuToggle } from "shared/hooks";

type NavListProps = {
  onClicked?: () => void;
  sx?: SxProps<Theme>;
};

const MapDefinitionToMenu = (
  user: UserProfile,
  definitions: RoutePathDefinition[],
  parent: string = "",
  onClicked?: () => void
): React.ReactNode => {
  return [
    [...definitions]
      .sort((a, b) => (a.use?.order ?? 0) - (b.use?.order ?? 0))
      .map((definition) => {
        if (!definition.use) return undefined;

        const { nav, requiredRoles } = definition.use;

        if (!nav || (requiredRoles && !requiredRoles?.some(rr => user.roles.indexOf(rr) >=0))) {
          return undefined;
        }

        const buildPath = joinPaths([parent, definition.path]);

        if (
          !definition.element &&
          definition?.children?.some((d) => d.element)
        ) {
          return (
            <CustomMenu
              key={buildPath}
              route={definition}
              onClicked={onClicked}
              hasParent={!!parent}
            />
          );
        }

        const to = generatePath(buildPath);

        return [
          to
            ? [
                <CustomNavListItem
                  key={buildPath}
                  path={to}
                  route={definition}
                  onClicked={onClicked}
                  hasParent={!!parent}
                />,
                definition.children
                  ? MapDefinitionToMenu(
                      user,
                      definition.children,
                      buildPath,
                      onClicked
                    )
                  : undefined,
              ]
            : null,
        ];
      }),
  ];
};

const NavBar = ({ sx, onClicked }: NavListProps) => {
  const user = useAppSelector(selectUser);

  if (!user) return <></>;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: "6.5rem",
        "& .MuiDrawer-paper": {
          width: "6.5rem",
          backgroundColor: "primary.light",
          border: 0,
        },
      }}
    >
      <Box component="nav">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="5rem"
        >
          <Box component={NavLink} to="/">
            <Image fileName="logo.png" alt="logo" width="75px" />
          </Box>
        </Box>

        <List
          sx={{
            color: "secondary.light",
            backgroundColor: "primary.light",
            p: 0,
            "& > .active": {
              "& .MuiListItem-root": {
                color: "primary.light",
                backgroundColor: "secondary.light",
                boxShadow: "0px 4px 4px 0px #00000040",
              },
              "& img": {
                  filter: (theme: any) => theme.palette.primary.filter.light,
                  width: () => "40px",
                  height: () => "40px",
              },
            },
            "& > :not(.active)": {
              "& > :hover": {
                backgroundColor: "primary.dark",
                boxShadow: "0px 4px 4px 0px #00000040",
              },
              "& img": {
                  filter: (theme: any) => theme.palette.secondary.filter.light,
                  width: () => "40px",
                  height: () => "40px",
              },
            },
            ...sx,
          }}
        >
          {MapDefinitionToMenu(user, routes, "", onClicked)}
        </List>
      </Box>
    </Drawer>
  );
};

type CustomListItemProps = {
  path?: string;
  route?: RoutePathDefinition;
  onClicked?: (event?: any) => void;
  name?: string;
  props?: any;
  hasParent: boolean;
  className?: string;
  addornments?: {
    start?: JSX.Element;
    end?: JSX.Element;
  };
};

const CustomListItem = ({
  route,
  addornments,
  onClicked,
  className,
  hasParent,
}: CustomListItemProps) => {
  const t9n = useAppSelector(selectT9N);

  if (!t9n || !route?.title) return <></>;

  return (
    <ListItem
      button
      onClick={onClicked}
      className={className}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
      }}
    >
      {route?.icon ? (
        <Image fileName={route?.icon} alt={t9n[route.title.toString()]} />
      ) : null}

      {addornments?.start ? addornments.start : null}

      <ListItemText className="listItem">
        <Typography
          variant="h6"
          color="inherit"
          sx={{
            fontWeight: "bold",
            fontSize: hasParent ? "1em" : "0.6em",
            textAlign: "center",
          }}
        >
          {t9n[route?.title?.toString()]}
        </Typography>
      </ListItemText>

      {addornments?.end ? addornments.end : null}
    </ListItem>
  );
};

const CustomNavListItem = ({ path, route, ...rest }: CustomListItemProps) => {
  if (!path) return <></>;

  return (
    <NavLink to={path} color="inherit">
      <Box>
        <CustomListItem route={route} {...rest} />
      </Box>
    </NavLink>
  );
};

const CustomMenu = ({ route, onClicked, ...rest }: CustomListItemProps) => {
    const { pathname } = useLocation();
    const user = useAppSelector(selectUser);
    const [menuAnchorEl, menuOpen, handleMenuClick, handleMenuClose] = useMenuToggle(onClicked);

  if (!user) return <></>;

  return (
    <>
      <Box className={pathname.includes(route?.path!) ? "active" : ""}>
        <CustomListItem
          route={route}
          addornments={{
            end: (
              <Image
                fileName="menu.svg"
                alt="menu"
                className={menuOpen ? "turnImg" : ""}
              />
            ),
          }}
          onClicked={handleMenuClick}
          {...rest}
        />
      </Box>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        sx={{
          "& > div": {
            display: "block",
          },
        }}
      >
        {route?.children
          ? MapDefinitionToMenu(
              user,
              route?.children,
              route.path,
              handleMenuClose
            )
          : undefined}
      </Menu>
    </>
  );
};

export default React.memo(NavBar);
