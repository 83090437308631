import { AppBar, Box, Button, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectT9N } from "features/locale";
import LanguageSelection from "features/locale/LanguageSelection";
import { selectUser } from "features/user";
import React from "react";
import { NavLink } from "react-router-dom";
import { Image } from "shared/components";
import { useMenuToggle } from "shared/hooks";

const Header = () => {
  const user = useAppSelector(selectUser);

  return (
    <>
      <AppBar
        component="header"
        position="sticky"
        color="transparent"
        sx={{
          pl: 2,
          boxShadow: "none",
          display: "flex",
          width: 1,
          backgroundColor: "secondary.light",
          height: "5rem",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            width: 1,
            minHeight: { xs: 0 },
            justifyContent: "space-between",
            alignItems: "flex-end",
            mt: 1,
          }}
        >
          <Box>
            <LanguageSelection />

            <Typography
              variant="h3"
              sx={{ display: { xs: "none", md: "block" }, mt: 1 }}
            >
              Axalta Maintenance Site
            </Typography>

            <Typography
              variant="h2"
              sx={{ display: { xs: "block", md: "none" }, mt: 1 }}
            >
              AMS
            </Typography>
          </Box>

          {user ? <UserMenu /> : null}
        </Toolbar>
      </AppBar>
    </>
  );
};

function UserMenu() {
  const [menuAnchorEl, menuOpen, handleMenuClick, handleMenuClose] =
    useMenuToggle();
  const user = useAppSelector(selectUser);
  const t9n = useAppSelector(selectT9N);

  return (
    <>
      <Button
        onClick={handleMenuClick}
        sx={{
          py: 0,
          display: "flex",
        }}
      >
        <Box sx={{ pr: 1 }}>
          <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
            {user?.name}
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
            {user?.roles && t9n?.[user.roles[0]]}
          </Typography>
        </Box>

        <Box
          sx={{
            "& > img": {
              filter: (theme: any) => theme.palette.primary.filter?.main,
            },
          }}
        >
          <Image fileName="profile.svg" alt="profile" />
        </Box>

        <Box
          sx={{
            "& > img": {
              filter: (theme: any) => theme.palette.primary.filter?.main,
            },
          }}
        >
          <Image
            fileName="menu.svg"
            alt="menu"
            className={menuOpen ? "turnImg" : ""}
          />
        </Box>
      </Button>

      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleMenuClose}>
        <MenuItem component={NavLink} to="/profile" onClick={handleMenuClose}>
          {t9n?.MyProfile}
        </MenuItem>

        <form
          id="logout-form"
          className="form-inline"
          action="auth/logout"
          method="post"
        >
          <MenuItem
            sx={{ color: "error.main" }}
            onClick={() => {
              (
                document.getElementById("logout-form") as HTMLFormElement
              ).submit();
              handleMenuClose();
            }}
          >
            {t9n?.Logout}
          </MenuItem>
        </form>
      </Menu>
    </>
  );
}

export default Header;
