import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, Button, Typography, DialogProps, } from "@mui/material";
import { useAppSelector } from "app";
import { selectT9N } from "features/locale";
import React from "react";

type Props = DialogProps & {
    title?: string;
    open: boolean;
    onConfirm?: (value?: any) => void;
    confirmTitle?: string;
    disableConfirm?: boolean;
    cancelTitle?: string;
    close: () => void;
    staticBackdrop?: boolean;
};

const Dialog = (props: React.PropsWithChildren<Props>) => {
    const {
        title,
        children,
        open,
        confirmTitle,
        disableConfirm,
        cancelTitle,
        onConfirm,
        close,
        staticBackdrop,
        ...rest
    } = props;
    const t9n = useAppSelector(selectT9N);

    const onClose = (event: object, reason: string) => {
        if (!staticBackdrop || reason !== "backdropClick")
            close();
    };

    return (
        <MuiDialog open={open} onClose={onClose} {...rest}>
            <Typography component={DialogTitle} variant="h3">
                {title}
            </Typography>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{ backgroundColor: "primary.light" }}>
                <Button onClick={close} sx={{ color: "secondary.light" }}>
                    {cancelTitle ?? t9n?.No}
                </Button>
                {onConfirm && (
                    <Button
                        disabled={disableConfirm}
                        onClick={() => {
                            close();
                            onConfirm();
                        }}
                        sx={{
                            backgroundColor: "secondary.light",
                            color: "primary.main",
                            "&:hover": {
                                backgroundColor: "primary.main",
                                color: "secondary.light",
                            },
                        }}
                    >
                        {confirmTitle ?? t9n?.Yes}
                    </Button>
                )}
            </DialogActions>
        </MuiDialog>
    );
};

export default Dialog;
