import {
    ActiveRoutePath,
    mapDefinitionToActivePath,
    routes,
} from "app/routing";
import { useLocation } from "react-router-dom";

export function useActiveRoutePaths(): ActiveRoutePath[] {
    const location = useLocation();
    const activeRoutePaths = mapDefinitionToActivePath(routes, location.pathname);
    return activeRoutePaths;
}
