import { Role } from "api/models/infrastructure";
import { RoutePathDefinition } from "app/routing";
import RequireAuth from "features/authentication/RequireAuth";
import Unauthorized from "features/authentication/Unauthorized";
import React from "react";
import { Navigate } from "react-router-dom";

const ExternalWeighing = React.lazy(() => import("../../features/externalWeighing/ExternalWeighing"));
const MyProfile = React.lazy(() => import("../../features/user/MyProfile"));
const QueueMonitor = React.lazy(() => import("../../features/queueMonitor/QueueMonitor"));
const UserConfiguration = React.lazy(
    () => import("../../features/configuration/components/UserConfiguration")
);
const NgsConfiguration = React.lazy(() => import("../../features/NgsConfiguration/components/NgsConfiguration"));

export const routes: RoutePathDefinition[] = [
    {
        path: "/",
        title: "Home",
        use: {
            nav: false,
        },
        element: (
            <></>
        ),
    },
    {
        path: "/externalWeighing",
        title: "ExternalWeighing",
        icon: "externalWeighing.svg",
        use: {
            nav: true,
            order: 2,
            requiredRoles: [Role.ExternalWeighing], // show in nav to these roles
        },
        element:/* actively deny access to these roles */ (
            <RequireAuth requiredRoles={[Role.ExternalWeighing]}>
                <ExternalWeighing />
            </RequireAuth>
        ),
    },
    {
        path: "/queuemonitor",
        title: "QueueMonitor",
        icon: "queuemonitor.svg",
        use: {
            nav: true,
            order: 3,
            requiredRoles: [Role.QueueMonitor],
        },
        element: (
            <RequireAuth requiredRoles={[Role.QueueMonitor]}>
                <QueueMonitor />
            </RequireAuth>
        ),
    },
    {
        path: "/ngsconfiguration",
        title: "Configuration",
        icon: "ngsConfiguration.svg",
        use: {
            nav: true,
            order: 3,
            requiredRoles: [Role.NgsConfiguration],
        },
        element: (
            <RequireAuth requiredRoles={[Role.NgsConfiguration]}>
                <NgsConfiguration />
            </RequireAuth>
        ),
    },
    {
        path: "/usermanagement",
        title: "UserManagement",
        icon: "settings.svg",
        use: {
            nav: true,
            order: 4,
            requiredRoles: [Role.Administrator, Role.UserManagement],
        },
        element: (
            <RequireAuth requiredRoles={[Role.Administrator, Role.UserManagement]}>
                <UserConfiguration />
            </RequireAuth>
        ),
    },
    {
        path: "/profile",
        title: "MyProfile",
        element: (
            <RequireAuth>
                <MyProfile />
            </RequireAuth>
        ),
    },
    {
        path: "/unauthorized",
        title: "Unauthorized",
        element: <Unauthorized />,
        use: {
            hideBreadCrumb: true,
        },
    },
    {
        title: "No match",
        path: "*",
        element: <Navigate to="/" replace />,
    },
];
