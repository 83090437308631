import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseResponse } from "api/models/infrastructure";
import { ResetQueueRequest } from "api/models/queues";
import { QueueMonitorSettings } from "api/models/settings";
import { queuesService, settingsService } from "api/services";


export const actions = {
    getQueueMonitorSettings: "queueMonitorSettings/get",
    resetQueue: "queue/reset",
};

export const getQueueMonitorSettings = createAsyncThunk(
    actions.getQueueMonitorSettings,
    async (_, thunkApi) => {
        return await settingsService.getSettings() || {} as QueueMonitorSettings;
    }
);

export const resetQueue = createAsyncThunk(
    actions.resetQueue,
    async (request: ResetQueueRequest) => {
        return await queuesService.resetQueue(request) || {} as BaseResponse;
    });
