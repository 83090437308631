import { authService } from "api/services";
import { useAppDispatch, useAppSelector } from "app";
import { getUserProfile, selectUser } from "features/user";
import React, { useEffect } from "react";

const AuthSuspense = ({ children }: React.PropsWithChildren<{}>) => {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    if (user === undefined) return <></>;
    if (user === null && !window.location.pathname.includes("/unauthorized")) {
        authService.login(window.location.pathname);
        return <></>;
    }

    return <>{children}</>;
};

export default AuthSuspense;
