import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
    isPending,
    isRejected,
    PayloadAction,
} from "@reduxjs/toolkit";
import { Brand, ExternalWeighing, SetExternalWeighing } from "api/models/externalWeighing";
import { externalWeighingService } from "api/services";
import { RootState } from "app";
import { shallowEqual } from "react-redux";
import { sortService } from "services";

import { ExternalWeighingList } from "./models";

export type ExternalWeighingsState = {
    externalWeighings: ExternalWeighing[];
    filter: ExternalWeighingFilter;
    brands?: Brand[];
    loading: {
        externalWeighings: boolean;
        companies: boolean;
        brands: boolean;
    };
};

type ExternalWeighingFilter = {
    requestCompany?: string;
    weighingCompany?: string;
    brandIds?: number[];
};

const initialState: ExternalWeighingsState = {
    externalWeighings: [],
    filter: {
        requestCompany: "",
        weighingCompany: "",
        brandIds: []
    },
    brands: [],
    loading: {
        externalWeighings: false,
        companies: false,
        brands: false
    }
};

const actions = {
    getExternalWeighings: "externalWeighing/get",
    setExternalWeighing: "externalWeighing/set",
    deleteExternalWeighing: "externalWeighing/delete",
    getBrands: "externalWeighing/brands"
}

export const getExternalWeighings = createAsyncThunk(
    actions.getExternalWeighings,
    async (_, thunkAPI) => {
        return await externalWeighingService.getExternalWeighings();
    }
);

export const setExternalWeighing = createAsyncThunk(
    actions.setExternalWeighing,
    async (externalWeighing: SetExternalWeighing, { dispatch }) => {
        let response = await externalWeighingService.setExternalWeighing(
            externalWeighing
        );
        // dispatch(getExternalWeighings); // uncomment if live *global* updates are wanted, not just "adding the next item"
        return response;
    }
);

export const deleteExternalWeighing = createAsyncThunk(
    actions.deleteExternalWeighing,
    async (id: number, thunkAPI) => {
        await externalWeighingService.deleteExternalWeighing(id);
        return id;
    }
);

export const getBrands = createAsyncThunk(
    actions.getBrands,
    async (_, thunkAPI) => {
        const response = await externalWeighingService.getBrands() || [];
        return response.sort(sortService.getComparer("asc", "name"));
    }
);

const externalWeighingSlice = createSlice({
    name: "externalWeighing",
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<ExternalWeighingFilter>) => {
            state.filter = action.payload;
        },
        clearFilters: (state) => {
            state.filter = initialState.filter;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getExternalWeighings.fulfilled,
            (state, action: PayloadAction<ExternalWeighing[] | null>) => {
                if (action.payload) state.externalWeighings = action.payload;
                state.loading.externalWeighings = false;
            }
        );
        builder.addCase(
            setExternalWeighing.fulfilled,
            (state, action: PayloadAction<ExternalWeighing | null>) => {
                if (action.payload) {
                    const index = state.externalWeighings.findIndex(
                        (weighing) => weighing.id === action.payload?.id
                    );
                    if (index > -1)
                        state.externalWeighings[index] = action.payload;
                    else state.externalWeighings?.unshift(action.payload);
                }
            }
        );
        builder.addCase(
            deleteExternalWeighing.fulfilled,
            (state, action: PayloadAction<number>) => {
                const index = state.externalWeighings.findIndex(
                    (weighing) => weighing.id === action.payload
                );
                if (index > -1) state.externalWeighings.splice(index, 1);
            }
        );
        builder.addCase(
            getBrands.fulfilled,
            (state, action: PayloadAction<Brand[] | null>) => {
                if (action.payload) state.brands = action.payload
                state.loading.brands = false;
            }
        );
        builder.addMatcher(isPending, (state, action) => {
            switch (action.type) {
                case `${actions.getExternalWeighings}/pending`:
                    state.loading.externalWeighings = true;
                    break;
                case `${actions.getBrands}/pending`:
                    state.loading.brands = true;
                    break;
            }
        });
        builder.addMatcher(isRejected, (state, action) => {
            switch (action.type) {
                case `${actions.getExternalWeighings}/rejected`:
                    state.loading.externalWeighings = false;
                    state.externalWeighings = initialState.externalWeighings;
                    break;
                case `${actions.getBrands}/rejected`:
                    state.loading.brands = false;
                    state.brands = initialState.brands;
                    break;
            }
        });
    },
});

const selectSelf = (state: RootState) => state.externalWeighing;

export const selectExternalWeighings = (
    sort: (a: ExternalWeighingList, b: ExternalWeighingList) => number
) =>
    createDraftSafeSelector(
        selectSelf,
        (state) => {
            var filteredList = state.externalWeighings
                .filter(
                    (item) =>
                        (!state.filter.requestCompany ||
                            item.requestCompany.name
                                .toLocaleLowerCase()
                                .includes(
                                    state.filter.requestCompany.toLocaleLowerCase()
                                ) ||
                            item.requestCompany.samlId
                                ?.toLocaleLowerCase()
                                .includes(
                                    state.filter.requestCompany.toLocaleLowerCase()
                                )) &&
                        (!state.filter.weighingCompany ||
                            item.weighingCompany.name
                                .toLocaleLowerCase()
                                .includes(
                                    state.filter.weighingCompany.toLocaleLowerCase()
                                ) ||
                            item.weighingCompany.samlId
                                ?.toLocaleLowerCase()
                                .includes(
                                    state.filter.weighingCompany.toLocaleLowerCase()
                                )) &&
                        (!state.filter.brandIds?.length ||
                            state.filter.brandIds.includes(item.brandId))
                )
                .map((item) => ({
                    id: item.id,
                    requestCompany:
                        item.requestCompany.name +
                        (item.requestCompany.samlId
                            ? ` (${item.requestCompany.samlId})`
                            : ""),
                    weighingCompany:
                        item.weighingCompany.name +
                        (item.weighingCompany.samlId
                            ? ` (${item.weighingCompany.samlId})`
                            : ""),
                    brand: state.brands?.find(b => b.id === item.brandId)?.name,
                    delivery24H: item.delivery24H,
                }));

            return filteredList.sort(sort);
        }
    );

export const selectFilter = createDraftSafeSelector(
    selectSelf,
    (state) => state.filter
);

export const selectBrands = createDraftSafeSelector(
    selectSelf,
    (state) => state.brands
);

export const selectLoadingExternalWeighingPage = createDraftSafeSelector(
    selectSelf,
    (state) =>
        state.loading.brands ||
        state.loading.externalWeighings ||
        shallowEqual(state.brands, initialState.brands)
);

export const { setFilters, clearFilters } = externalWeighingSlice.actions;
export default externalWeighingSlice.reducer;
