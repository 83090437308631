import { QueueMonitorSettings } from "api/models/settings";
import api from "api/utils";

const baseUrl = "api/settings";

const getSettings = async () => {
    return api.query<undefined, QueueMonitorSettings>({
        baseUrl: baseUrl
    });
}

const settingsService = {
    getSettings,
}

export default settingsService;