import { createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, CompanySearchReply } from "api/models/companies";
import { companyService } from "api/services";
import { RootState } from "app";

export type CompanyState = {
    companies: Company[];
};

const initialState: CompanyState = {
    companies: [],
};

export const searchCompanies = createAsyncThunk(
    "externalWeighing/companies",
    async (company?: string) => {
        return await companyService.searchCompanies({ searchTerm: company });
    }
);

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        clearCompanies: (state) => {
            state.companies = initialState.companies;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            searchCompanies.fulfilled,
            (state, action: PayloadAction<CompanySearchReply | null>) => {
                if (action.payload) state.companies = action.payload.companies;
            }
        );
    }
});

const selectSelf = (state: RootState) => state.company;
export const selectCompanies = createDraftSafeSelector(
    selectSelf,
    (state) => state.companies
);

export const { clearCompanies } = companySlice.actions;
export default companySlice.reducer;
