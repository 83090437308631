import { CircularProgress } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";

const delay = 200;
let timer: NodeJS.Timeout | null = null;

type Props = {
  loading: boolean;
};

const Loader = ({ loading, children }: PropsWithChildren<Props>) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loading)
      timer = setTimeout(() => {
        setIsLoading(true);
      }, delay);

    if (!loading && timer) {
      clearTimeout(timer);
      setIsLoading(false);
    }
  }, [loading]);

  if (isLoading) 
    return <CircularProgress />;

  return <>{children}</>;
};

export default Loader;