import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import { routes } from "app/routing";
import AuthSuspense from "features/authentication/AuthSuspense";
import Localization from "features/locale/Localization";
import { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { cookieKeys, cookieService } from "services";
import { theme } from "themes";

import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./components/Layout";
import { RoutesRenderer } from "./routing/RoutesRenderer";

const updateDomHeader = () => {
    var version = cookieService.get(cookieKeys.version);
    if (version) upsertMetaTag("version", version);
};

const upsertMetaTag = (name: any, content: string) => {
    var meta = document.getElementsByTagName("meta")[name];
    if (meta) meta.content = content;
};

const App = () => {
    useEffect(() => {
        updateDomHeader();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <AuthSuspense>
                    <Localization>
                        <CssBaseline />
                        <BrowserRouter>
                            <Layout>
                                <Suspense fallback={<CircularProgress />}>
                                    <RoutesRenderer routes={routes} />
                                </Suspense>
                            </Layout>
                        </BrowserRouter>
                    </Localization>
                </AuthSuspense>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
