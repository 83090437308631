import { createAsyncThunk } from "@reduxjs/toolkit";
import { SetUserRole } from "api/models/configuration";
import { configurationService } from "api/services";
import { setMessage } from "features/message";

export const getUsers = createAsyncThunk(
    "get/configuration/users",
    async (arg, thunkAPI) => {
        return await configurationService.getUsers();
    }
);

export const setUserRole = createAsyncThunk(
    "put/configuration/userrole",
    async (arg: SetUserRole, thunkAPI) => {
        await configurationService.setUserRole(arg);
        return arg;
    }
);

export const deleteUser = createAsyncThunk(
    "delete/configuration/user",
    async (userId: number, { dispatch, rejectWithValue }) => {
        try {
            await configurationService.deleteUser(userId);
            return userId;
        } catch (error) {
            dispatch(
                setMessage({
                    message: "UserDeleteError",
                    status: "error",
                })
            );
            return rejectWithValue(null);
        }
    }
);
