export type Order = "asc" | "desc";

function getComparer<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
    return order === "desc"
        ? (a, b) => descendingComparer(a, b, orderBy)
        : (a, b) => -descendingComparer(a, b, orderBy);
}

function descendingComparer<T>(a: T, b: T, orderBy: keyof T) {
    let valA: T[keyof T] | string = a[orderBy];
    let valB: T[keyof T] | string = b[orderBy];

    if (typeof valA === "string" && typeof valB === "string") {
        valA = valA.toLocaleLowerCase();
        valB = valB.toLocaleLowerCase();
    }

    if (valA == null) return -1;
    if (valB == null) return 1;

    if (valB < valA) {
        return -1;
    }
    if (valB > valA) {
        return 1;
    }
    return 0;
}

const sortService = { getComparer };
export default sortService;
